export const ING_FIELD_BLANK = {
  name: '',
  amount: '',
  notes: '',
  groupId: 1,
  elemId: 0,
};

export const ING_GROUP_BLANK = {
  name: '',
  notes: '',
  groupId: 1,
  elemId: 0,
};

export const IMG_BLANK = {
  imagePath: '',
  id: 0,
};
