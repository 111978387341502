export const FETCH_RECIPES_START = 'FETCH_RECIPES_START';
export const FETCH_RECIPES_SUCCESS = 'FETCH_RECIPES_SUCCESS';
export const FETCH_RECIPES_FAILURE = 'FETCH_RECIPES_FAILURE';

export const FETCH_MORE_RECIPES_START = 'FETCH_MORE_RECIPES_START';
export const FETCH_MORE_RECIPES_SUCCESS = 'FETCH_MORE_RECIPES_SUCCESS';
export const FETCH_MORE_RECIPES_FAILURE = 'FETCH_MORE_RECIPES_FAILURE';

export const FETCH_QS_START = 'FETCH_QS_START';
export const FETCH_QS_SUCCESS = 'FETCH_QS_SUCCESS';
export const FETCH_MORE_QS_SUCCESS = 'FETCH_MORE_QS_SUCCESS';
export const FETCH_QS_FAILURE = 'FETCH_QS_FAILURE';

export const FETCH_IMAGES_START = 'FETCH_IMAGES_START';
export const FETCH_IMAGES_SUCCESS = 'FETCH_IMAGES_SUCCESS';
export const FETCH_IMAGES_FAILURE = 'FETCH_IMAGES_FAILURE';

export const FETCH_INGRED_START = 'FETCH_INGRED_START';
export const FETCH_INGRED_SUCCESS = 'FETCH_INGRED_SUCCESS';
export const FETCH_INGRED_FAILURE = 'FETCH_INGRED_FAILURE';

export const FETCH_INGRED_GROUP_START = 'FETCH_INGRED_GROUP_START';
export const FETCH_INGRED_GROUP_SUCCESS = 'FETCH_INGRED_GROUP_SUCCESS';
export const FETCH_INGRED_GROUP_FAILURE = 'FETCH_INGRED_GROUP_FAILURE';

export const FETCH_SELECTED_RECIPE_START = 'FETCH_SELECTED_RECIPE_START';
export const FETCH_SELECTED_RECIPE_SUCCESS = 'FETCH_SELECTED_RECIPE_SUCCESS';
export const FETCH_SELECTED_RECIPE_FAILURE = 'FETCH_SELECTED_RECIPE_FAILURE';

export const SET_SELECTED_RECIPE = 'SET_SELECTED_RECIPE';

export const SET_NOTIFICATION_DETAILS = 'SET_NOTIFICATION_DETAILS';

export const AUTH_START = 'AUTH_START';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const AUTH_NOT_LOGGED_IN = 'AUTH_NOT_LOGGED_IN';

export const SET_SCREEN = 'SET_SCREEN';
